import { Controller } from "stimulus";

const makeArray = (list) => {
  return Array.prototype.slice.call(list);
};

export default class extends Controller {
  connect() {
    this.showEmbed();
  }

  showEmbed() {
    this.element.innerHTML = this.data.get("html");
    this.activateScriptElements(this.element);
  }

  activateScriptElements() {
    for (const inactiveScriptElement of this.getInactiveScriptElements()) {
      const scriptElement = this.createScriptElement(inactiveScriptElement);
      inactiveScriptElement.parentElement.replaceChild(
        scriptElement,
        inactiveScriptElement
      );
    }
  }

  getInactiveScriptElements() {
    return makeArray(this.element.querySelectorAll("script"));
  }

  createScriptElement(sourceElement) {
    const targetScriptElement = document.createElement("script");
    targetScriptElement.textContent = sourceElement.textContent;
    targetScriptElement.async = false;
    for (const { name, value } of makeArray(sourceElement.attributes)) {
      targetScriptElement.setAttribute(name, value);
    }
    return targetScriptElement;
  }
}
