import { Controller } from "stimulus";

const makeArray = (list) => {
  return Array.prototype.slice.call(list);
};

const injections = {
  "ins[data-revive-id]": {
    id: "revive",
    src: "//ads.golfpunkhq.com/live/www/delivery/asyncjs.php",
  },
  "ins[data-ad-slot]": function (element) {
    if (typeof window.adsbygoogle !== "object") {
      window.adsbygoogle = [];
    }
    makeArray(element.querySelectorAll("ins[data-ad-slot]")).forEach((ad) => {
      window.adsbygoogle.push({});
    });
  },
};

export default class extends Controller {
  connect() {
    this.injectScripts();
  }

  injectScripts() {
    for (const selector in injections) {
      if (this.element.querySelector(selector)) {
        this.inject(injections[selector]);
      }
    }
  }

  inject(injection) {
    if (typeof injection === "object") {
      this.injectScriptNode(injection.src, injection.id);
    } else if (typeof injection === "function") {
      injection(this.element);
    }
  }

  injectScriptNode(src, id) {
    if (id && document.getElementById(id)) {
      return;
    }

    const firstScriptElement = document.getElementsByTagName("script")[0];
    const newScriptElement = document.createElement("script");
    newScriptElement.src = src;
    if (id) {
      newScriptElement.id = id;
    }
    firstScriptElement.parentNode.insertBefore(
      newScriptElement,
      firstScriptElement
    );
  }
}
