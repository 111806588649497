import { Controller } from "stimulus";
import Cookies from "./cookies";

const cookieName = "_golfpunk_cookies_accept";
const cookieValue = "yes";
const expires = 365 * 24 * 60;

export default class extends Controller {
  connect() {
    this.showOrHide();
  }

  showOrHide() {
    this.element.classList.toggle("cookieBanner--open", !this.bannerAccepted());
  }

  accept() {
    Cookies.set(cookieName, cookieValue, expires);
    this.showOrHide();
  }

  bannerAccepted() {
    return cookieValue === Cookies.get(cookieName);
  }
}
