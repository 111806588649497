// Main application pack for user-facing (that is, non-admin) pages.
// Here we load:
// * Rails UJS helpers (for any Rails pages that use it).
// * Turbolinks (enabled on both PHP and Rails pages so that navigation
//   between them works correctly).
// * Ahoy for first-party analytics
// * Stimulus and non-admin Stimulus controllers (used for the cookie
//   pop-up).

require("@rails/ujs").start();
// require("turbolinks").start();
// require("channels");

import ahoy from "ahoy.js";
ahoy.configure({ cookies: false });
ahoy.trackAll();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers";
